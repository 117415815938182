
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import AreaSelect from '@/components/area-select.vue'
import OrderLogistics from '@/components/order/order-logistics.vue'
import {
  apiOrderDetail,
  apiOrderDeliver
} from '@/api/shop'
@Component({
  components: {
    LsDialog,
    AreaSelect,
    OrderLogistics
  }
})
export default class OrderDetail extends Vue {
  // S Data

  // 订单详情ID
  id: any = 0

  // 订单数据
  orderData: any = {
    admin_order_btn: {
      remark_btn: 1,
      cancel_btn: 0,
      confirm_btn: 0,
      logistics_btn: 0,
      refund_btn: 0,
      address_btn: 1,
      price_btn: 1
    },
    address: {
      contact: '',
      mobile: ''
    }
  }

  // 商家备注
  remarks = ''

  // 运费更改
  express_price = ''

  // 商品价格
  goods_price = ''

  // E Data

  // S Methods
  // 获取订单详情
  getOrderDetail () {
    apiOrderDetail({ id: this.id }).then(res => {
      this.orderData = res
    })
  }

  // 取消订单
  orderCancel () {
    // apiOrderCancel({ id: this.id }).then(res => {
    //   this.getOrderDetail()
    // })
  }

  // E Methods

  created () {
    this.id = this.$route.query.id
    this.id && this.getOrderDetail()
  }
}
